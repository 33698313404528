import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const ArrangementsPage = () => (
  <Layout>
    <SEO
      title="Arrangements"
      keywords={[`conductor`, `musical supervisor`, `musicologist`]}
    />
    <h3 className="font-sans font-light mb-4 text-2xl sm:text-3xl underline">
      Arrangements
    </h3>

    <div className="border-2 border-purple-500 bg-purple-200 p-3 mr-1 rounded-lg shadow-md">
      <p>
        John has provided arrangements for a variety of orchestras, soloists,
        theatrical productions and broadcasts. A detailed list is available on
        request.
      </p>
      <br />
      <p>
        For further enquiries please{" "}
        <Link className="underline" to="/contact">
          contact John directly
        </Link>
        .
      </p>
    </div>
  </Layout>
)

export default ArrangementsPage
